import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useAuthStore from '../../stores/useAuthStore';
import { FaUserCircle, FaChevronDown, FaSignOutAlt, FaChartBar, FaGraduationCap, FaTrophy, FaUsers, FaDownload, FaFlask, FaCog } from 'react-icons/fa';

function Header({ transparentHeader = true }) {
  const { isAuthenticated, logout, user } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const closeAllMenus = useCallback(() => {
    setIsDesktopMenuOpen(false);
    setIsMobileMenuOpen(false);
  }, []);

  useEffect(() => {
    const cleanup = () => {
      closeAllMenus();
    };
    return cleanup;
  }, [location.pathname, closeAllMenus]);

  const handleLogout = () => {
    logout();
    navigate('/');
    closeAllMenus();
  };

  const scrollToAbout = (e) => {
    e.preventDefault();
    closeAllMenus();
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const aboutSection = document.getElementById('about-us');
        if (aboutSection) aboutSection.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      const aboutSection = document.getElementById('about-us');
      if (aboutSection) aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      if (transparentHeader) {
        const scrollPosition = window.scrollY;
        setIsScrolled(scrollPosition > 50);
      } else {
        setIsScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [transparentHeader]);

  return (
    <>
      <header className={`fixed w-full z-50 h-16 transition-all duration-300 border-b ${
        isScrolled
          ? 'bg-gradient-to-b from-premium-dark/95 to-premium-dark/90 backdrop-blur-md border-white/[0.02]'
          : 'bg-transparent border-transparent'
      }`}>
        <div className="container mx-auto px-4 py-1 md:px-6 md:py-2">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-3" onClick={closeAllMenus}>
              <motion.img
                src="https://storage.googleapis.com/mosaic-images/Mosaic%20Logo.webp"
                alt="Mosaic Logo"
                className="w-6 h-6 md:w-8 md:h-8 object-cover opacity-90"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              />
            </Link>

            <nav className="hidden md:flex space-x-8 items-center absolute left-1/2 transform -translate-x-1/2">
              <NavLink to="/services" onClick={closeAllMenus}>Services</NavLink>
              <NavLink to="/data-export" onClick={closeAllMenus}>Data Export</NavLink>
              <NavLink to="/contact" onClick={closeAllMenus}>Contact</NavLink>
            </nav>

            <div className="hidden md:block">
              {isAuthenticated ? (
                <UserMenu
                  user={user}
                  handleLogout={handleLogout}
                  closeMenu={closeAllMenus}
                  isDesktopMenuOpen={isDesktopMenuOpen}
                  setIsDesktopMenuOpen={setIsDesktopMenuOpen}
                />
              ) : (
                <AuthButtons closeMenu={closeAllMenus} />
              )}
            </div>
            <MobileMenuButton isOpen={isMobileMenuOpen} toggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
          </div>
        </div>
      </header>
      <AnimatePresence>
        {isMobileMenuOpen && (
          <MobileMenu
            isAuthenticated={isAuthenticated}
            user={user}
            handleLogout={handleLogout}
            scrollToAbout={scrollToAbout}
            closeMobileMenu={closeMobileMenu}
          />
        )}
      </AnimatePresence>
    </>
  );
}

const NavLink = ({ to, children, ...props }) => (
  <Link
    to={to}
    className="text-gray-400 hover:text-white transition-all duration-300 ease-in-out text-sm font-medium relative group flex items-center gap-1"
    {...props}
  >
    {children}
    <span className="absolute -bottom-0.5 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500/40 via-purple-500/40 to-blue-500/40 transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
    <span className="absolute -bottom-0.5 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500 transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out blur-sm"></span>
  </Link>
);

const UserMenu = ({
  user,
  handleLogout,
  closeMenu,
  isDesktopMenuOpen,
  setIsDesktopMenuOpen
}) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDesktopMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setIsDesktopMenuOpen]);

  const handleItemClick = useCallback((to, action) => {
    setIsDesktopMenuOpen(false);
    closeMenu();

    setTimeout(() => {
      if (action) {
        action();
      }
      if (to) {
        navigate(to);
      }
    }, 10);
  }, [navigate, closeMenu, setIsDesktopMenuOpen]);

  const menuItems = [
    // Main Features Group
    {
      group: "Main Features",
      items: [
        {
          icon: FaChartBar,
          to: "/admin/dashboard",
          text: "Dashboard",
        },
        {
          icon: FaGraduationCap,
          to: "/journey",
          text: "Learning Journey",
        },
        {
          icon: FaTrophy,
          to: "/leaderboard",
          text: "Leaderboard",
        }
      ]
    },
    // Tools & Resources Group
    {
      group: "Tools & Resources",
      items: [
        {
          icon: FaFlask,
          to: "/llm",
          text: "AI Use Cases",
        },
        {
          icon: FaUsers,
          to: "/community",
          text: "Community",
        },
        {
          icon: FaDownload,
          to: "/data-export",
          text: "Export Data",
        }
      ]
    }
  ];

  return (
    <div className="relative" ref={menuRef}>
      <motion.button
        onClick={() => setIsDesktopMenuOpen(!isDesktopMenuOpen)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="flex items-center space-x-3 text-gray-200 px-3 py-1.5 rounded-xl
                   bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                   backdrop-blur-md border border-white/[0.08] hover:border-white/[0.12]
                   transition-all duration-300 group"
      >
        <div className="w-7 h-7 rounded-full bg-gradient-to-r from-blue-500/10 to-indigo-500/10
                       border border-white/[0.08] flex items-center justify-center
                       group-hover:border-white/[0.12] transition-all duration-300">
          <FaUserCircle className="w-5 h-5 text-blue-400 group-hover:text-blue-300" />
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-xs font-medium">{user?.email?.split('@')[0]}</span>
          <FaChevronDown className={`h-3 w-3 text-gray-400 transition-transform duration-300
                                   ${isDesktopMenuOpen ? 'rotate-180' : ''}`} />
        </div>
      </motion.button>

      <AnimatePresence>
        {isDesktopMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-64 rounded-xl overflow-hidden
                     bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                     backdrop-blur-md border border-white/[0.08]
                     shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
          >
            {/* Enhanced User Info Section with Quick Actions */}
            <div className="p-4 border-b border-white/[0.08] bg-gradient-to-r from-blue-500/5 to-indigo-500/5">
              <div className="flex items-start space-x-3">
                <div className="flex-1">
                  <p className="text-sm font-medium text-white">{user?.email?.split('@')[0]}</p>
                  <p className="text-xs text-gray-400">{user?.email}</p>

                  {/* Quick Actions - Now more subtle and integrated */}
                  <div className="flex items-center justify-end gap-1 mt-2">
                    <motion.button
                      onClick={() => handleItemClick(`/user-profile/${user?.id}`)}
                      whileHover={{ scale: 1.02 }}
                      className="flex items-center px-2.5 py-1 text-xs text-gray-400 hover:text-white
                               rounded-md transition-colors duration-200"
                    >
                      <FaUserCircle className="w-3 h-3 mr-1.5" />
                      Profile
                    </motion.button>
                    <span className="text-gray-600">•</span>
                    <motion.button
                      onClick={() => handleItemClick('/settings')}
                      whileHover={{ scale: 1.02 }}
                      className="flex items-center px-2.5 py-1 text-xs text-gray-400 hover:text-white
                               rounded-md transition-colors duration-200"
                    >
                      <FaCog className="w-3 h-3 mr-1.5" />
                      Settings
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2">
              {menuItems.map((group, groupIndex) => (
                <div key={group.group}>
                  <div className="px-3 py-2 text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                    {group.group}
                  </div>

                  <div className="space-y-0.5 mb-3">
                    {group.items.map((item, itemIndex) => (
                      <UserMenuItem
                        key={`${groupIndex}-${itemIndex}`}
                        icon={item.icon}
                        to={item.to}
                        text={item.text}
                        onClick={() => handleItemClick(item.to)}
                      />
                    ))}
                  </div>

                  {groupIndex < menuItems.length - 1 && (
                    <div className="border-t border-white/[0.08] my-2"></div>
                  )}
                </div>
              ))}

              {/* Logout Button */}
              <div className="border-t border-white/[0.08] pt-2 mt-2">
                <motion.button
                  onClick={() => handleItemClick(null, handleLogout)}
                  whileHover={{ scale: 1.02, x: 5 }}
                  className="flex items-center w-full px-3 py-2 text-sm text-gray-300
                           hover:text-white rounded-lg group transition-all duration-300 justify-end"
                >
                  <div className="order-2 ml-3 p-2 rounded-lg bg-gray-800/50 border border-white/[0.08]
                                group-hover:bg-red-500/10 group-hover:border-red-500/20
                                transition-all duration-300">
                    <FaSignOutAlt className="h-4 w-4 text-gray-400 group-hover:text-red-400" />
                  </div>
                  <span className="order-1">Logout</span>
                </motion.button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const UserMenuItem = ({ icon: Icon, text, onClick }) => (
  <motion.button
    onClick={onClick}
    whileHover={{ x: 4 }}
    className="flex items-center w-full px-3 py-2.5 text-sm text-gray-300
             hover:text-white rounded-lg transition-all duration-200 group"
  >
    <div className="w-8 h-8 flex items-center justify-center rounded-lg
                  bg-gray-800/50 border border-white/[0.08]
                  group-hover:bg-blue-500/10 group-hover:border-blue-500/20
                  transition-all duration-300">
      <Icon className="h-4 w-4 text-gray-400 group-hover:text-blue-400" />
    </div>
    <span className="ml-3">{text}</span>
  </motion.button>
);

const AuthButtons = ({ closeMenu }) => (
  <div className="flex space-x-3">
    <Link to="/login" onClick={closeMenu}>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="px-4 py-1.5 rounded-xl text-xs text-gray-300
                   bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                   backdrop-blur-md border border-white/[0.08] hover:border-white/[0.12]
                   transition-all duration-300"
      >
        Login
      </motion.button>
    </Link>
    <Link to="/signup" onClick={closeMenu}>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="px-4 py-1.5 rounded-xl text-xs text-white
                   bg-gradient-to-r from-blue-600 to-indigo-600
                   hover:from-blue-500 hover:to-indigo-500
                   transition-all duration-300
                   shadow-[0_0_15px_rgba(59,130,246,0.2)]
                   hover:shadow-[0_0_20px_rgba(59,130,246,0.3)]"
      >
        Sign Up
      </motion.button>
    </Link>
  </div>
);

const MobileMenuButton = ({ isOpen, toggle }) => {
  const variant = isOpen ? "opened" : "closed";

  const top = {
    closed: {
      rotate: 0,
      translateY: 0
    },
    opened: {
      rotate: 45,
      translateY: 2
    }
  };
  const center = {
    closed: {
      opacity: 1
    },
    opened: {
      opacity: 0
    }
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0
    },
    opened: {
      rotate: -45,
      translateY: -2
    }
  };

  return (
    <motion.button
      className="md:hidden w-8 h-8 relative focus:outline-none"
      onClick={toggle}
    >
      <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
      <motion.span
        className="block absolute h-0.5 w-5 bg-white transform transition duration-500 ease-in-out"
        animate={variant}
        variants={top}
        style={{ top: '35%', left: '50%', x: '-50%' }}
      />
      <motion.span
        className="block absolute h-0.5 w-5 bg-white transform transition duration-500 ease-in-out"
        animate={variant}
        variants={center}
        style={{ top: '50%', left: '50%', x: '-50%' }}
      />
      <motion.span
        className="block absolute h-0.5 w-5 bg-white transform transition duration-500 ease-in-out"
        animate={variant}
        variants={bottom}
        style={{ top: '65%', left: '50%', x: '-50%' }}
      />
    </motion.button>
  );
};

const MobileMenu = ({ isAuthenticated, user, handleLogout, scrollToAbout, closeMobileMenu }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: '-100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '-100%' }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-gray-900 z-[100] overflow-y-auto"
    >
      <div className="flex flex-col h-full p-4 md:p-6 pt-20">
        <div className="flex justify-end">
          <button onClick={closeMobileMenu} className="text-white p-2 absolute top-4 right-4">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <nav className="flex flex-col space-y-4 mt-6">
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.1 }}>
            <MobileNavLink to="/services" closeMobileMenu={closeMobileMenu}>Services</MobileNavLink>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }}>
            <MobileNavLink to="/#about-us" onClick={(e) => { e.preventDefault(); scrollToAbout(e); closeMobileMenu(); }}>About Us</MobileNavLink>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }}>
            <MobileNavLink to="/contact" closeMobileMenu={closeMobileMenu}>Contact</MobileNavLink>
          </motion.div>
        </nav>
        <div className="mt-8 border-t border-gray-700 pt-6 flex-grow">
          {isAuthenticated ? (
            <div className="space-y-4">
              <p className="text-lg font-medium text-white">{user?.email}</p>
              <div className="space-y-3">
                <MobileUserMenuItem icon={FaChartBar} to="/admin/dashboard" text="Dashboard" onClick={closeMobileMenu} />
                <MobileUserMenuItem icon={FaFlask} to="/practice" text="Practice" onClick={closeMobileMenu} />
                <MobileUserMenuItem icon={FaGraduationCap} to="/llm" text="Practice AI" onClick={closeMobileMenu} />
                <MobileUserMenuItem icon={FaTrophy} to="/leaderboard" text="Leaderboard" onClick={closeMobileMenu} />
                <MobileUserMenuItem icon={FaUsers} to="/community" text="Community Forum" onClick={closeMobileMenu} />
                <MobileUserMenuItem icon={FaDownload} to="/data-export" text="Data Export" onClick={closeMobileMenu} />
              </div>
              <button
                onClick={() => { handleLogout(); closeMobileMenu(); }}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out mt-6"
              >
                Logout
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <Link to="/login" onClick={closeMobileMenu}>
                <button className="w-full bg-transparent border border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                  Login
                </button>
              </Link>
              <Link to="/signup" onClick={closeMobileMenu}>
                <button className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const MobileNavLink = ({ to, children, closeMobileMenu, ...props }) => (
  <Link
    to={to}
    className="text-white text-2xl font-medium hover:text-blue-300 transition duration-300 ease-in-out"
    onClick={closeMobileMenu}
    {...props}
  >
    {children}
  </Link>
);

const MobileUserMenuItem = ({ icon: Icon, to, text, onClick }) => (
  <Link
    to={to}
    className="flex items-center space-x-4 text-gray-300 hover:text-white
               bg-gradient-to-br from-gray-900/50 via-gray-900/60 to-gray-800/50
               backdrop-blur-md rounded-xl p-4 border border-white/[0.08]
               hover:border-white/[0.12] group transition-all duration-300"
    onClick={onClick}
  >
    <div className="p-2 rounded-lg bg-gray-800/50 border border-white/[0.08]
                    group-hover:bg-blue-500/10 group-hover:border-blue-500/20
                    transition-all duration-300">
      <Icon className="w-4 h-4 text-gray-400 group-hover:text-blue-400" />
    </div>
    <span>{text}</span>
  </Link>
);

export default Header;