import React from 'react';
import { motion } from 'framer-motion';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
    this.setState({ error, errorInfo });
    // Here you can also send the error and errorInfo to a logging service
  }

  render() {
    if (this.state.hasError) {
      return (
        <motion.div
          className="error-boundary"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1>Oops! A Glitch in the Matrix</h1>
          <p>Our digital artisans are weaving a fix as we speak. Refresh the page or return to the homepage to continue your journey.</p>
          {process.env.NODE_ENV === 'development' && (
            <motion.details
              style={{ whiteSpace: 'pre-wrap' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ duration: 0.3 }}
            >
              <summary>Unveil the Mystery (Error Details)</summary>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </motion.details>
          )}
        </motion.div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;