import React from 'react';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../stores/useAuthStore';
import Header from './Header';
import WorkspaceHeader from './WorkspaceHeader';
import Footer from './Footer';

const ConditionalHeaderFooter = ({ children, headerProps }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthStore();

  const pathsToHideHeaderFooter = ['/signup', '/login'];
  const workspaceRoutes = ['/lessons', '/use-cases', '/practice'];

  const isUseCaseDetail = location.pathname.includes('/lessons/') && location.pathname.includes('/use-cases/');

  const isWorkspacePath = workspaceRoutes.some(path =>
    location.pathname.startsWith(path)
  );
  const hideHeaderFooter = pathsToHideHeaderFooter.includes(location.pathname);

  return (
    <div className="relative min-h-screen flex flex-col">
      {!hideHeaderFooter && (
        isWorkspacePath ? (
          <WorkspaceHeader {...headerProps} />
        ) : (
          <Header transparentHeader={!isUseCaseDetail} />
        )
      )}
      <div className={`flex-grow ${isWorkspacePath ? 'bg-slate-900' : ''} ${
        !hideHeaderFooter && isUseCaseDetail ? 'mt-16' : ''
      }`}>
        {children}
      </div>
      {!hideHeaderFooter && !isWorkspacePath && <Footer />}
    </div>
  );
};

export default ConditionalHeaderFooter;