import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useAuthStore from '../../stores/useAuthStore';
import { FaCog, FaBell, FaFire, FaGem, FaHeart, FaEnvelope, FaUserEdit } from 'react-icons/fa';
import { IoArrowBack, IoChevronDown } from 'react-icons/io5';
import ProgressDonut from '../practice_v2/ProgressDonut';

function WorkspaceHeader({ lessonTitle, lessonId }) {
  const { user, logout } = useAuthStore();
  const navigate = useNavigate();
  const [notifications] = useState(3);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const menuRef = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="fixed w-full z-50 h-16 bg-premium-dark backdrop-blur-md border-b border-white/[0.02] shadow-premium">
      <div className="h-full w-full flex items-center px-4">
        {/* Left Section */}
        <div className="flex items-center space-x-6">
          <motion.button
            whileHover={{ scale: 1.02, x: -2 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/journey')}
            className="flex items-center text-slate-400 hover:text-white transition-all duration-300 ease-in-out px-2 py-1 rounded-full bg-slate-800/30 hover:bg-slate-800/50 border border-white/[0.02] hover:border-white/[0.05] shadow-sm text-xs"
          >
            <IoArrowBack className="w-3 h-3 mr-1.5" />
            <span className="font-medium text-xs">Journey</span>
          </motion.button>

          <div className="h-5 w-px bg-slate-700" />

          <Link to="/">
            <motion.img
              src="https://storage.googleapis.com/mosaic-images/Mosaic%20Logo.webp"
              alt="Mosaic Logo"
              className="w-4 h-4 md:w-5 md:h-5 object-cover opacity-70 hover:opacity-100 transition-opacity"
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
            />
          </Link>

          {(lessonId || lessonTitle) && (
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2 px-3 py-1 bg-gradient-to-r from-slate-800/20 to-slate-900/20 rounded-full border border-white/[0.02] text-xs">
                <span className="text-slate-400">Lesson {lessonId}</span>
                <span className="text-slate-500">•</span>
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-500 font-medium">
                  {lessonTitle}
                </span>
              </div>

              {lessonId && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="relative flex items-center"
                >
                  <div className="relative group">
                    <ProgressDonut
                      lessonId={lessonId}
                      size={28}
                      variant="header"
                    />
                    <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 blur-xl" />
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>

        {/* Center Section - Removed progress donut */}
        <div className="flex-1" />

        {/* Right Section */}
        <div className="flex items-center space-x-6">
          {/* Notifications */}
          <div className="relative" ref={notificationRef}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowNotifications(!showNotifications)}
              className="relative p-2 text-slate-400 hover:text-white transition-colors rounded-full hover:bg-white/[0.02]"
            >
              <FaBell className="w-5 h-5" />
              {notifications > 0 && (
                <span className="absolute -top-1 -right-1 bg-gradient-to-r from-blue-500 to-indigo-500 text-xs text-white w-4 h-4 rounded-full flex items-center justify-center shadow-lg">
                  {notifications}
                </span>
              )}
            </motion.button>

            <AnimatePresence>
              {showNotifications && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-slate-800 ring-1 ring-black ring-opacity-5"
                >
                  <div className="p-3 border-b border-slate-700">
                    <h3 className="text-sm font-medium text-white">Notifications</h3>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {[
                      {
                        id: 1,
                        title: "New Achievement Unlocked!",
                        message: "You've completed your first lesson 🎉",
                        time: "2 hours ago",
                        icon: <FaGem className="text-blue-400" />
                      },
                      {
                        id: 2,
                        title: "Profile Update",
                        message: "Your profile has been successfully updated",
                        time: "1 day ago",
                        icon: <FaUserEdit className="text-green-400" />
                      },
                      {
                        id: 3,
                        title: "New Message",
                        message: "You received feedback on your latest submission",
                        time: "2 days ago",
                        icon: <FaEnvelope className="text-purple-400" />
                      }
                    ].map((notification) => (
                      <div
                        key={notification.id}
                        className="p-4 hover:bg-slate-700/50 transition-colors border-b border-slate-700/50"
                      >
                        <div className="flex items-start space-x-3">
                          <div className="p-2 bg-slate-700/30 rounded-full">
                            {notification.icon}
                          </div>
                          <div>
                            <p className="text-sm font-medium text-white">{notification.title}</p>
                            <p className="text-xs text-slate-400 mt-1">{notification.message}</p>
                            <p className="text-xs text-slate-500 mt-1">{notification.time}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="p-2 text-center border-t border-slate-700">
                    <button className="text-xs text-blue-400 hover:text-blue-300 transition-colors">
                      View All Notifications
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Settings */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/settings')}
            className="text-slate-400 hover:text-white transition-colors"
          >
            <FaCog className="w-5 h-5" />
          </motion.button>

          {/* User Menu - Modified */}
          <div className="relative pl-4 border-l border-slate-700" ref={menuRef}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className="flex items-center space-x-3"
            >
              <div className="text-right">
                <p className="text-sm font-medium text-white">{user?.email}</p>
              </div>
              <IoChevronDown className="w-4 h-4 text-slate-400" />
            </motion.button>

            <AnimatePresence>
              {showProfileMenu && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-slate-800 ring-1 ring-black ring-opacity-5"
                >
                  <div className="p-3 border-b border-slate-700">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center space-x-2">
                        <FaFire className="text-orange-500" />
                        <span className="text-slate-200 text-sm">Streak</span>
                      </div>
                      <span className="text-white font-medium">3</span>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center space-x-2">
                        <FaGem className="text-blue-400" />
                        <span className="text-slate-200 text-sm">Points</span>
                      </div>
                      <span className="text-white font-medium">3,440</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <FaHeart className="text-red-500" />
                        <span className="text-slate-200 text-sm">Lives</span>
                      </div>
                      <span className="text-white font-medium">5</span>
                    </div>
                  </div>
                  <div className="py-1" role="menu">
                    <button
                      onClick={() => navigate(`/user-profile/${user?.id}`)}
                      className="w-full text-left px-4 py-2 text-sm text-slate-300 hover:bg-slate-700"
                      role="menuitem"
                    >
                      Profile
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-slate-300 hover:bg-slate-700"
                      role="menuitem"
                    >
                      Help
                    </button>
                    <div className="h-px bg-slate-700 my-1" />
                    <button
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-slate-700"
                      role="menuitem"
                    >
                      Log Out
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </header>
  );
}

export default WorkspaceHeader;