import { create } from 'zustand'
import axios from '../api/axiosConfig'

const useAuthStore = create((set, get) => ({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,

  login: async (email, password) => {
    set({ isLoading: true, error: null })
    try {
      const response = await axios.post('/auth/login', {
        login_input: email,
        password
      }, {
        withCredentials: true
      });

      const { user, access_token } = response.data
      localStorage.setItem('access_token', access_token);
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      set({
        user,
        isAuthenticated: true,
        isLoading: false,
        error: null
      })
    } catch (error) {
      set({
        isLoading: false,
        error: error.response?.data?.error || 'Login failed'
      })
      throw error
    }
  },

  signup: async (userData) => {
    set({ isLoading: true, error: null })
    try {
      const response = await axios.post('/auth/register', {
        email: userData.email,
        password: userData.password,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        is_admin: userData.is_admin || false
      })

      const { user, access_token } = response.data

      localStorage.setItem('access_token', access_token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

      set({
        user,
        isAuthenticated: true,
        isLoading: false
      })
      return true
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Signup failed'
      const errorDetails = error.response?.data?.details || []

      set({
        error: errorMessage,
        errorDetails: errorDetails,
        isLoading: false
      })

      // If we have detailed errors, format them for display
      if (errorDetails.length > 0) {
        throw {
          type: errorMessage,
          details: errorDetails
        }
      }

      throw errorMessage
    }
  },

  logout: () => {
    localStorage.removeItem('access_token')
    delete axios.defaults.headers.common['Authorization']

    set({
      user: null,
      isAuthenticated: false,
      error: null
    })
  },

  checkAuth: async () => {
    set({ isLoading: true });
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        set({ isLoading: false, isAuthenticated: false, user: null });
        return false;
      }

      // Set default header for all future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const { data } = await axios.get('/auth/verify');

      set({
        user: data.user,
        isAuthenticated: true,
        isLoading: false
      });
      return true;
    } catch (error) {
      console.error('Auth check failed:', error);
      localStorage.removeItem('access_token');
      delete axios.defaults.headers.common['Authorization'];

      set({
        user: null,
        isAuthenticated: false,
        isLoading: false
      });
      return false;
    }
  }
}))

export default useAuthStore