import React from 'react'
import { motion } from 'framer-motion'
import { useLlmDashboardStore } from '../../stores/llmDashboardStore'

const CompletionMessage = ({ percentage, progress }) => {
  if (percentage === 100) {
    return (
      <motion.div
        className="text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        <span className="text-xs text-green-400 font-medium">Journey Complete!</span>
      </motion.div>
    );
  }

  if (percentage > 0) {
    return (
      <motion.span
        className="text-xs text-gray-400"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        Progress
      </motion.span>
    );
  }

  return (
    <motion.span
      className="text-xs text-gray-400"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.7 }}
    >
      Start Your Journey
    </motion.span>
  );
};

const ProgressDonut = ({ lessonId, size = 120, variant = 'default' }) => {
  const progress = useLlmDashboardStore(state => state.progressStats)

  // Initialize progress when component mounts
  React.useEffect(() => {
    useLlmDashboardStore.getState().initializeProgress(lessonId)
  }, [lessonId])

  const getProgressColor = (percentage) => {
    if (percentage < 33) return 'stroke-red-500'
    if (percentage < 66) return 'stroke-yellow-500'
    return 'stroke-green-500'
  }

  if (!progress) return null

  const percentage = progress ? (progress.completed / progress.total) * 100 : 0
  const actualSize = variant === 'header' ? 48 : size
  const radius = actualSize / 2
  const circumference = 2 * Math.PI * (radius - (variant === 'header' ? 10 : 10))
  const strokeDashoffset = circumference - (percentage / 100) * circumference
  const progressColor = getProgressColor(percentage)

  // Header variant specific styles
  const strokeWidth = variant === 'header' ? 4.5 : 8
  const circleRadius = radius - (variant === 'header' ? 10 : 10)

  return (
    <div className="relative" style={{ width: actualSize, height: actualSize }}>
      {/* Background circle */}
      <svg
        className="transform -rotate-90"
        width={actualSize}
        height={actualSize}
      >
        <circle
          cx={radius}
          cy={radius}
          r={circleRadius}
          className="stroke-gray-700/30"
          strokeWidth={strokeWidth}
          fill="none"
        />

        {/* Animated progress circle */}
        <motion.circle
          cx={radius}
          cy={radius}
          r={circleRadius}
          className={progressColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
          initial={{ strokeDashoffset: circumference }}
          animate={{ strokeDashoffset }}
          style={{
            strokeDasharray: circumference
          }}
          transition={{
            duration: 1,
            ease: "easeInOut"
          }}
        />
      </svg>

      {variant === 'default' && (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <motion.span
            className={`text-${size < 100 ? 'lg' : '2xl'} font-bold ${progressColor.replace('stroke-', 'text-')}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {Math.round(percentage)}%
          </motion.span>

          <CompletionMessage percentage={percentage} progress={progress} />

          {progress && (
            <motion.div
              className="absolute -bottom-8 text-sm text-gray-400"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9 }}
            >
              {progress.completed}/{progress.total} Cases
            </motion.div>
          )}
        </div>
      )}

      {variant === 'header' && (
        <>
          {/* Percentage in the center */}
          <div className="absolute inset-0 flex items-center justify-center">
            <span className={`text-[10px] font-semibold ${progressColor.replace('stroke-', 'text-')}`}>
              {Math.round(percentage)}%
            </span>
          </div>

          {/* Diagonal fraction */}
          {progress && (
            <div className="absolute -right-9 top-1/2 -translate-y-1/2 w-7 h-7">
              <span className="absolute -top-[2px] -left-[2px] text-[11px] font-medium text-gray-300">
                {progress.completed}
              </span>
              <svg
                className="absolute inset-0 w-full h-full"
                viewBox="0 0 12 12"
                style={{ transform: 'scale(1.2)' }}
              >
                <line
                  x1="9"
                  y1="3"
                  x2="3"
                  y2="9"
                  className="stroke-gray-600"
                  strokeWidth="0.55"
                />
              </svg>
              <span className="absolute -bottom-[2px] -right-[2px] text-[11px] font-medium text-gray-400">
                {progress.total}
              </span>
            </div>
          )}
        </>
      )}
      {/* ... rest of the component ... */}
    </div>
  );
};

export default ProgressDonut;