import React from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-gray-300 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
    whileHover={{ y: -2 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const SocialIcon = ({ href, icon: Icon }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-400 hover:text-white transition duration-300 ease-in-out"
    whileHover={{ y: -2, scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon size={24} />
  </motion.a>
);

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white py-12 relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('/path/to/texture.png')] opacity-5 mix-blend-overlay"></div>
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
          <div className="space-y-4 flex flex-col items-center md:items-start">
            <Link to="/" className="flex items-center space-x-3 justify-center md:justify-start">
              <motion.img
                src="https://storage.googleapis.com/mosaic-images/Mosaic%20Logo.webp"
                alt="Mosaic Logo"
                className="h-4 w-4"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              />
              <span className="text-2xl font-bold bg-clip-text text-white">
                mosaic
              </span>
            </Link>
            <p className="text-sm text-gray-400 max-w-xs">
              Empowering financial intelligence through cutting-edge AI and machine learning solutions.
            </p>
          </div>
          <div className="mt-8 md:mt-0">
            <h3 className="text-lg font-semibold mb-4 text-gray-200">Quick Links</h3>
            <ul className="space-y-2">
              <li><FooterLink href="/services">Services</FooterLink></li>
              <li><FooterLink href="/about">About Us</FooterLink></li>
              <li><FooterLink href="/contact">Contact</FooterLink></li>
              <li><FooterLink href="/careers">Careers</FooterLink></li>
            </ul>
          </div>
          <div className="mt-8 md:mt-0">
            <h3 className="text-lg font-semibold mb-4 text-gray-200">Legal</h3>
            <ul className="space-y-2">
              <li><FooterLink href="/privacy">Privacy Policy</FooterLink></li>
              <li><FooterLink href="/terms">Terms of Service</FooterLink></li>
              <li><FooterLink href="/cookies">Cookie Policy</FooterLink></li>
            </ul>
          </div>
          <div className="flex justify-center md:justify-start items-center space-x-6 mt-8 md:mt-0">
            <SocialIcon href="https://twitter.com/Mosaic_aml" icon={FaTwitter} />
            <SocialIcon href="https://www.linkedin.com/company/Mosaic" icon={FaLinkedin} />
            <SocialIcon href="https://github.com/investigamer-cfa" icon={FaGithub} />
          </div>
        </div>
        <div className="mt-12 text-center">
          <p className="text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} Mosaic. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;