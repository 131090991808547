import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from '../api/axiosConfig'
import { playSound } from '../utils/audioUtils'
import correctSound from '../assets/sounds/correct.mp3'
import wrongSound from '../assets/sounds/wrong.mp3'
import transitionSound from '../assets/sounds/transition.mp3'
export const useLlmDashboardStore = create(devtools((set, get) => ({
  // UI States
  isLoading: true,
  selectedAction: null,
  feedback: { show: false, isCorrect: false, message: '' },
  isSubmitted: false,
  isReportDialogOpen: false,
  startedAt: null,
  progressStats: null,

  // Customer Outreach State
  isCustomerOutreachEnabled: false,
  secondaryDecision: null,

  // Actions
  setLoading: (loading) => set({ isLoading: loading }),
  setSelectedAction: (action) => set({ selectedAction: action }),
  setFeedback: (feedback) => set({ feedback }),
  setIsSubmitted: (submitted) => set({ isSubmitted: submitted }),
  setReportDialogOpen: (open) => set({ isReportDialogOpen: open }),
  setStartTime: () => set({ startedAt: new Date().toISOString() }),

  // Customer Outreach Actions
  setCustomerOutreachEnabled: (enabled) => {
    console.log('Setting customer outreach enabled:', enabled)
    playSound(transitionSound)
    set({ isCustomerOutreachEnabled: enabled })
  },
  setSecondaryDecision: (decision) => {
    console.log('Setting secondary decision:', decision)
    playSound(transitionSound)
    set({ secondaryDecision: decision })
  },

  // Business Logic
  submitAnswer: async ({ answer, useCaseId, lessonId, startedAt, userId, skippedCustomerOutreach }) => {
    try {
      const response = await axios.post('/practice_v2/submit', {
        useCaseId,
        lessonId,
        answer,
        startedAt,
        userId,
        skippedCustomerOutreach
      })

      console.log('Store submission response:', response.data)

      const isCorrect = response.data.result.isCorrect
      const nextUseCase = response.data.result.nextUseCase

      if (!nextUseCase && isCorrect) {
        console.warn('No next case data received')
      }

      if (skippedCustomerOutreach) {
        set({
          feedback: {
            show: true,
            isCorrect: false,
            message: 'Incorrect. You must review customer outreach before closing this case.'
          },
          isSubmitted: true
        })
        playSound(wrongSound)
        return {
          ...response.data,
          result: {
            ...response.data.result,
            isCorrect: false
          }
        }
      }

      if (isCorrect) {
        const currentProgress = get().progressStats || { completed: 0, total: 0 }
        set({
          progressStats: {
            ...currentProgress,
            completed: currentProgress.completed + 1,
          }
        })

        axios.get(`/use-cases/progress/${lessonId}`)
          .then(progressResponse => {
            set({ progressStats: progressResponse.data })
          })
          .catch(error => {
            console.error('Failed to update progress:', error)
          })
      }

      const message = isCorrect
        ? nextUseCase
          ? 'Correct! Moving to next case...'
          : 'Correct! You\'ve completed all cases!'
        : nextUseCase?.is_similar
          ? 'Incorrect. Try this similar case...'
          : 'Incorrect. Please try again.'

      set({
        feedback: {
          show: true,
          isCorrect,
          message
        },
        isSubmitted: true
      })

      playSound(isCorrect ? correctSound : wrongSound)
      return response.data
    } catch (error) {
      console.error('Store submission error:', error)
      set({
        feedback: {
          show: true,
          isCorrect: false,
          message: error.message || 'An error occurred. Please try again.'
        }
      })
      throw error
    }
  },

  submitReport: async (useCaseId, comment) => {
    try {
      const response = await axios.post(`/use-cases/${Number(useCaseId)}/report`, {
        comment
      })

      set({
        feedback: {
          show: true,
          isCorrect: true,
          message: 'Thank you for your feedback!'
        },
        isReportDialogOpen: false // Close dialog after successful submission
      })
      return response.data
    } catch (error) {
      set({
        feedback: {
          show: true,
          isCorrect: false,
          message: error?.response?.data?.error || 'Error submitting report. Please try again.'
        }
      })
      throw error
    }
  },

  getFirstIncompleteUseCase: async (lessonId) => {
    try {
      const response = await axios.get(`/current/${lessonId}`)
      return response.data
    } catch (error) {
      throw error
    }
  },

  initializeProgress: async (lessonId) => {
    try {
      const response = await axios.get(`/use-cases/progress/${lessonId}`)
      set({ progressStats: response.data })
    } catch (error) {
      console.error('Failed to initialize progress:', error)
    }
  },

  // Enhanced submit logic
  submitDecision: async (useCaseId, decision, startTime) => {
    const state = get();
    const payload = {
      decision,
      customerOutreach: state.isCustomerOutreachEnabled,
      secondaryDecision: state.secondaryDecision,
      startTime,
      endTime: new Date().toISOString()
    };

    try {
      const response = await axios.post(`/api/use-cases/${useCaseId}/submit`, payload);
      set({ submissionResult: response.data });
      return response.data;
    } catch (error) {
      console.error('Error submitting decision:', error);
      throw error;
    }
  },

  // Reset state
  resetDecisionState: () => set({
    isCustomerOutreachEnabled: false,
    secondaryDecision: null
  }),

  // Reset store
  reset: () => set({
    isLoading: true,
    selectedAction: null,
    feedback: { show: false, isCorrect: false, message: '' },
    isSubmitted: false,
    isReportDialogOpen: false,
    startedAt: null,
    progressStats: null,
  }),

  // Add a new action to check the user's decision
  checkUserDecision: (state, userDecision) => {
    const { correctAnswer, customerOutreach } = state.currentCase;

    if (correctAnswer === 'escalate') {
      if (userDecision === 'escalate') {
        state.decisionResult = 'correct';
      } else if (userDecision === 'close') {
        state.decisionResult = 'wrong';
      } else if (userDecision === 'customer_outreach') {
        state.decisionResult = 'wrong_no_transition';
      }
    } else if (correctAnswer === 'close') {
      if (!customerOutreach) {
        if (userDecision === 'close') {
          state.decisionResult = 'correct';
        } else if (userDecision === 'escalate') {
          state.decisionResult = 'wrong';
        }
      } else {
        if (userDecision === 'close') {
          state.decisionResult = 'wrong';
        } else if (userDecision === 'escalate') {
          state.decisionResult = 'wrong';
        } else if (userDecision === 'customer_outreach') {
          state.awaitingSecondDecision = true;
        }
      }
    }
  },

  checkSecondDecision: (state, userDecision) => {
    if (userDecision === 'close') {
      state.decisionResult = 'correct';
    } else if (userDecision === 'escalate') {
      state.decisionResult = 'wrong';
    }
    state.awaitingSecondDecision = false;
  },
})))