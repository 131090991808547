import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiDownload, FiCalendar, FiFilter, FiLock, FiBarChart } from 'react-icons/fi';
import DateRangePicker from './DateRangePicker';
import DataPreview from './DataPreview';
import ExportOptions from './ExportOptions';
import { ExportUseCaseData } from '../utils/dataExport';
import { Spotlight } from './landing/Spotlight';

// Update FeatureCard component
const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white p-8 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
  >
    <div className="text-4xl mb-4 text-blue-600">{icon}</div>
    <h3 className="text-2xl font-semibold text-white mb-4">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </motion.div>
);

const DataExport = () => {
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [selectedFields, setSelectedFields] = useState([]);
  const [format, setFormat] = useState('json');
  const [encryption, setEncryption] = useState(false);
  const [loading, setLoading] = useState(false);

  // Add preview data state
  const [previewData, setPreviewData] = useState([
    {
      persona: "Small Business Owner",
      financialProfile: "Medium Risk",
      transactionData: "€25,000 monthly turnover",
      suspiciousActivity: "None detected",
      auxiliaryData: "Regular supplier payments",
      analystDecision: "Approved",
      difficultyLevel: "Medium",
      context: "Business expansion"
    },
    {
      persona: "Retail Investor",
      financialProfile: "Low Risk",
      transactionData: "€5,000 monthly investments",
      suspiciousActivity: "Pattern analysis required",
      auxiliaryData: "Regular market trades",
      analystDecision: "Under Review",
      difficultyLevel: "Low",
      context: "Portfolio diversification"
    },
    {
      persona: "Corporate Entity",
      financialProfile: "High Risk",
      transactionData: "€100,000 monthly transfers",
      suspiciousActivity: "Unusual patterns",
      auxiliaryData: "International transfers",
      analystDecision: "Flagged",
      difficultyLevel: "High",
      context: "Cross-border trading"
    }
  ]);

  const fieldOptions = [
    { value: 'financialProfile', label: 'Financial Profile' },
    { value: 'transactionData', label: 'Transaction Data' },
    { value: 'suspiciousActivity', label: 'Suspicious Activity' },
    { value: 'persona', label: 'Persona' },
    { value: 'auxiliaryData', label: 'Auxiliary Data' },
    { value: 'analystDecision', label: 'Analyst Decision' },
    { value: 'difficultyLevel', label: 'Difficulty Level' },
    { value: 'context', label: 'Context' },
  ];

  const handleFieldChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedFields([...selectedFields, value]);
    } else {
      setSelectedFields(selectedFields.filter(field => field !== value));
    }
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      await ExportUseCaseData(dateRange, selectedFields, format, encryption);
      alert('Use case data exported successfully!');
    } catch (error) {
      console.error('Error exporting use case data:', error);
      alert('Failed to export use case data. Please try again.');
    }
    setLoading(false);
  };

  // Pivot table layout component
  const PivotTablePreview = ({ data, selectedFields }) => (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-800">
        <thead className="bg-gray-900/90">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Field
            </th>
            {data.map((_, idx) => (
              <th key={idx} className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Record {idx + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800 bg-gray-900/50">
          {selectedFields.map((field) => (
            <tr key={field} className="bg-white/5 backdrop-blur-sm hover:bg-white/10 transition-colors duration-200">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200">
                {fieldOptions.find(f => f.value === field)?.label || field}
              </td>
              {data.map((row, idx) => (
                <td key={idx} className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {row[field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="bg-premium-dark min-h-screen py-20 relative overflow-hidden">
      {/* Spotlights */}
      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="white"
      />
      <Spotlight
        className="top-40 right-0 md:right-60 md:top-20"
        fill="blue"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10 mt-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center space-y-4 mb-20"
        >
          <h1 className="text-4xl md:text-6xl font-bold">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-gray-200 via-gray-400 to-gray-600">
              Advanced Use Case
            </span>{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-600">
              Data Export
            </span>
          </h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-400 max-w-3xl mx-auto p-6
                     border border-white/[0.08] rounded-2xl
                     bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                     backdrop-blur-md"
          >
            Export and analyze your use case data with our enterprise-grade tools.
          </motion.p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
          {/* Export Options Panel */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                     backdrop-blur-md rounded-2xl p-8 border border-white/[0.08]
                     shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
          >
            <h2 className="text-2xl font-semibold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-blue-400">
              Export Options
            </h2>

            {/* Date Range */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Date Range
              </label>
              <DateRangePicker onChange={setDateRange} />
            </div>

            {/* Field Selection */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Select Fields
              </label>
              <div className="grid grid-cols-2 gap-3">
                {fieldOptions.map((field) => (
                  <label key={field.value} className="relative flex items-start py-2">
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        value={field.value}
                        onChange={handleFieldChange}
                        className="h-4 w-4 rounded border-gray-700 bg-gray-900 text-blue-600
                                 focus:ring-2 focus:ring-blue-500/20 focus:ring-offset-0"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <span className="text-gray-300 hover:text-white transition-colors">
                        {field.label}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            {/* Export Format */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Export Format
              </label>
              <select
                onChange={(e) => setFormat(e.target.value)}
                className="w-full px-4 py-3 text-gray-200 bg-gray-900/50
                         border border-white/[0.08] rounded-xl
                         focus:outline-none focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20
                         transition-all duration-300"
              >
                <option value="json">JSON</option>
                <option value="csv">CSV</option>
                <option value="xlsx">Excel</option>
              </select>
            </div>

            {/* Encryption Toggle */}
            <label className="flex items-center space-x-3 mb-8 group cursor-pointer">
              <input
                type="checkbox"
                onChange={(e) => setEncryption(e.target.checked)}
                className="h-4 w-4 rounded border-gray-700 bg-gray-900 text-blue-600
                         focus:ring-2 focus:ring-blue-500/20 focus:ring-offset-0"
              />
              <span className="text-gray-300 group-hover:text-white transition-colors">
                Enable End-to-End Encryption
              </span>
            </label>

            {/* Export Button */}
            <motion.button
              onClick={handleExport}
              disabled={loading}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-600
                       hover:from-blue-500 hover:to-indigo-500 text-white font-semibold rounded-xl
                       flex items-center justify-center gap-2 transition-all duration-300
                       disabled:opacity-50 disabled:cursor-not-allowed
                       shadow-[0_0_20px_rgba(59,130,246,0.2)]
                       hover:shadow-[0_0_25px_rgba(59,130,246,0.3)]"
            >
              {loading ? (
                <SparklesIcon className="w-5 h-5 animate-spin" />
              ) : (
                <>
                  <FiDownload className="w-5 h-5" />
                  Export Use Case Data
                </>
              )}
            </motion.button>
          </motion.div>

          {/* Preview Panel */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-gradient-to-br from-gray-900/90 via-gray-900/95 to-gray-800/90
                     backdrop-blur-md rounded-2xl p-8 border border-white/[0.08]
                     shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
          >
            <h2 className="text-2xl font-semibold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-blue-400">
              Data Preview
            </h2>

            <PivotTablePreview
              data={previewData}
              selectedFields={selectedFields}
            />
          </motion.div>
        </div>

        {/* Features Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-20"
        >
          <h2 className="text-2xl font-semibold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-blue-400">
            Why Our Use Case Data Export is Revolutionary
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <FeatureCard
              icon={<FiCalendar />}
              title="Flexible Date Ranges"
              description="Export use cases from any time period with our intuitive date range selector."
            />
            <FeatureCard
              icon={<FiFilter />}
              title="Customizable Fields"
              description="Choose exactly which use case data fields to export, tailoring the output to your needs."
            />
            <FeatureCard
              icon={<FiLock />}
              title="Enterprise-Grade Security"
              description="Optional end-to-end encryption ensures your sensitive use case data remains protected."
            />
            <FeatureCard
              icon={<FiBarChart />}
              title="AI-Ready Formats"
              description="Export in ML-friendly formats, perfect for training custom AML models with real-world use cases."
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DataExport;