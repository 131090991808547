import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from './components/common/ErrorBoundary';
import ConditionalHeaderFooter from './components/layout/ConditionalHeaderFooter';
import useAuthStore from './stores/useAuthStore';
import DataExport from './components/DataExport';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

// Lazy load components
const Login = lazy(() => import('./components/auth/Login'));
const Signup = lazy(() => import('./components/auth/Signup'));
const LandingPage = lazy(() => import('./components/landing/LandingPage'));
const Practice = lazy(() => import('./components/practice/Practice'));

const AdminDashboard = lazy(() => import('./components/dashboard/AdminDashboard'));
const UserProfile = lazy(() => import('./components/dashboard/UserProfile'));
const Settings = lazy(() => import('./components/dashboard/Settings'));
const UseCaseProfile = lazy(() => import('./components/dashboard/UseCaseProfile'));
const Leaderboard = lazy(() => import('./components/leaderboard/Leaderboard'));
const CommunityForum = lazy(() => import('./components/community/Community'));
const Services = lazy(() => import('./components/services/Services'));
const Contact = lazy(() => import('./components/contact/Contact'));

const LLMLandingPage = lazy(() => import('./components/practice_v2/LlmLanding'));
const ScenarioGenerator = lazy(() => import('./components/llm/ScenarioGenerator'));
const UseCaseDetail = lazy(() => import('./components/practice_v2/UseCaseDetail'));
const Journey = lazy(() => import('./components/practice_v2/Journey'));

// Protected Route Component
const ProtectedRoute = ({ children, requireAuth = true }) => {
  const { isAuthenticated, isLoading } = useAuthStore();
  const location = useLocation();

  // Show loading spinner while checking auth
  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  // Redirect if not authenticated
  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect if authenticated but accessing auth pages
  if (!requireAuth && isAuthenticated) {
    const from = location.state?.from?.pathname || '/journey';
    return <Navigate to={from} replace />;
  }

  return children;
};

const App = () => {
  const { checkAuth } = useAuthStore();

  // Check auth state on app load
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense fallback={
            <div className="flex h-screen items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500" />
            </div>
          }>
            <div className="relative min-h-screen">
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={
                  <ConditionalHeaderFooter>
                    <LandingPage />
                  </ConditionalHeaderFooter>
                } />
                <Route
                  path="/login"
                  element={
                    <ProtectedRoute requireAuth={false}>
                      <Login />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <ProtectedRoute requireAuth={false}>
                      <Signup />
                    </ProtectedRoute>
                  }
                />

                {/* Protected Routes */}
                <Route
                  path="/practice/:lessonId"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <Practice />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/dashboard"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <AdminDashboard />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-profile/:userId"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <UserProfile />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <Settings />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/use-case-profile/:useCaseId"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <UseCaseProfile />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/leaderboard"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <Leaderboard />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/community"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <CommunityForum />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/services"
                  element={
                    <ConditionalHeaderFooter>
                      <Services />
                    </ConditionalHeaderFooter>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <ConditionalHeaderFooter>
                      <Contact />
                    </ConditionalHeaderFooter>
                  }
                />
                <Route
                  path="/data-export"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <DataExport />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />

                {/* LLM Routes */}
                <Route
                  path="/llm"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <LLMLandingPage />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/lessons/:lessonId/use-cases/:useCaseId"
                  element={
                    <ProtectedRoute>
                      <UseCaseDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/journey"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <Journey />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/llm/scenario-generator"
                  element={
                    <ProtectedRoute>
                      <ConditionalHeaderFooter>
                        <ScenarioGenerator />
                      </ConditionalHeaderFooter>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </Suspense>
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;