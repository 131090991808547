import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({ onChange }) => {
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleChange = (update) => {
    setDateRange(update);
    onChange({ start: update[0], end: update[1] });
  };

  return (
    <div className="mb-6">
      <label htmlFor="date-range" className="block text-sm font-medium text-gray-700 mb-2">
        Date Range
      </label>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded-md"
        placeholderText="Select date range"
        id="date-range"
      />
    </div>
  );
};

export default DateRangePicker;